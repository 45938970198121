<template>
  <div id="BeforePay">
    <main class="main-box">
      <div class="main">
        <h3>课程信息</h3>
        <div class="content">
          <img :src="data.courseCover" alt="" />
          <br />
          <p>{{ data.courseName }}</p>
          <br />
          <br />
          <br />
          <br />
          <p class="price">
            ￥
            <span>{{ data.coursePrice }}</span>
          </p>
        </div>
        <br />
        <br />
        <br />
        <hr />
        <div class="total">
          <p>需支付金额</p>
          <p>
            课程金额
            <span>
              ￥
              <span>{{ data.coursePrice }}</span>
            </span>
          </p>
          <p>
            应付金额
            <span class="color">
              ￥
              <span>{{ data.coursePrice }}</span>
            </span>
          </p>
        </div>
        <button class="pay" @click="ToPay(data.id)">去付款</button>
      </div>
    </main>
  </div>
</template>

<script>
import API from '@/api';
// import cookies from '@/utils/cookies'
export default {
  name: 'BeforePay',
  data() {
    return {
      data: '',
      courseId: {
        id: '',
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 去付款
    ToPay(id) {
      this.$router.push({
        path: '/Pay',
        query: {
          id,
        },
      });
    },
    // 获取信息
    getDetail() {
      // if (cookies.get('token')) {
      this.courseId.id = this.$route.query.id;
      API.Detail(this.courseId).then((res) => {
        if (res.message.success === true) {
          this.data = res.message.data;
        }
      });
      // } else {
      //   console.log('登录页面')
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  width: 1200px;
  height: 80vh;
  margin: auto;
  .main {
    height: 80vh;
    padding: 60px 30px;
  }
  .content {
    margin: 30px 0px;
    img {
      width: 400px;
      height: 200px;
      margin-right: 30px;
      float: left;
    }
    .price {
      font-size: 30px;
      color: #f7b515;
    }
  }
  .total {
    margin: 30px;
    p {
      margin: 20px;
      .color {
        color: #f7b515;
        font-size: 30px;
      }
    }
  }
  .pay {
    font-size: 18px;
    margin-top: 30px;
    float: right;
    width: 200px;
    height: 50px;
    outline: none;
    border: transparent;
    background: transparent;
    cursor: pointer;
    background: #f7b515;
    color: #ffffff;
    border-radius: 5px;
  }
}
</style>
